<template>
  <div class="relative flex flex-col w-screen h-screen min-h-screen max-h-screen">
    <div class="flex-initial w-full h-16 bg-blue-500">
      <div class="w-full h-full py-1 px-4 flex items-center justify-between">
        <div class="">
          <router-link
            to="coucou"
            tag="div"
            active-class="bg-blue-400"
            class="
            flex items-center gap-2
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
          >
            <ArrowLeftIcon class="h-4" />
            Revenir aux prévisions
          </router-link>
        </div>

        <div class="flex items-center">
          <router-link
            :to="{ name: 'OpeningConfigurationPreparateur' }"
            tag="div"
            active-class="bg-blue-400"
            class="
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
          >
            Préparateur
          </router-link>

          <router-link
            :to="{ name: 'OpeningConfigurationBoulanger' }"
            tag="div"
            active-class="bg-blue-400"
            class="
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
          >
            Boulanger
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex-1 py-2 overflow-hidden">
      <router-view />
    </div>
  </div>
</template>

<script>
import { ArrowLeftIcon } from '@vue-hero-icons/outline';

export default {
  components: { ArrowLeftIcon },
};
</script>

<style>
html, body {
  background: #edf1f8;
}

</style>
